import 'web-animations-js';
import Misc from './misc';

export class SliderServices {
  
  constructor() {
    
    if ( !document.querySelector('.grid-services') ) {
      return false;
    }

    this.sliderList = document.querySelectorAll('.grid-services .item')
    this.tabsInner = document.querySelector('.grid-services--inner');
    this.tabsWrapper = document.querySelector('.grid-services');
    this.contentWrapper = document.querySelector('.content-services--list');

    this.handleClick = this.handleClick.bind(this);
    this.hideContentWrapper = this.hideContentWrapper.bind(this);
    this.closeMobileDialog = this.closeMobileDialog.bind(this);
    this.resetContentWrapper = this.resetContentWrapper.bind(this);

    this.sliderList.forEach( item => {
      item.addEventListener('click', this.handleClick );
    });

    document.querySelectorAll('.content-services--list .item').forEach( item => {
      if (!item.classList.contains('active') || this.isMobileLayout() ) {
        item.style.display = 'none';
      }
    });

    if ( this.isMobileLayout() ) {
      this.tabsWrapper.querySelector('.item.active').classList.remove('active');
      this.contentWrapper.querySelector('.item.active').classList.remove('active');
      this.hideContentWrapper();
    }

    window.addEventListener('resize', () => {


      // If no longer mobile layout and no element selected, we display the first one
      if ( !this.isMobileLayout() && !this.contentWrapper.querySelector('.item.active')) {
        this.fadeIn(this.tabsWrapper.querySelector('.item'));
      } 

      if ( this.isMobileLayout() && this.tabsWrapper.querySelector('.item.active')) {
        this.updateHeight(this.contentWrapper.querySelector('.item.active'));
        this.tabsInner.classList.add('out');
        this.contentWrapper.classList.add('active');
        this.contentWrapper.querySelector('.item.active .op-close').addEventListener('click', this.closeMobileDialog );
      }

      if ( !this.isMobileLayout() ) {
        this.contentWrapper.classList.remove('active');
        this.tabsInner.classList.remove('out');
      }

    });

  }

  isMobileLayout() {
    return document.body.classList.contains('mobile-layout');
  }

  handleClick(event) {
    event.preventDefault();
    const currentIndex = Array.prototype.indexOf.call(event.currentTarget.parentNode.children, event.currentTarget);

    const currentContent = document.querySelector('.content-services--list .item.active') ? document.querySelector('.content-services--list .item.active') : document.querySelector('.content-services--list .item');
    const selectedContent = document.querySelector(`.content-services--list .item:nth-of-type(${currentIndex+1})`);
    
    // If clicked element is already active
    if ( selectedContent.classList.contains('active') || event.currentTarget.classList.contains('active') ) {
      return false;
    }

    // Update tab if exists
    if ( this.tabsWrapper.querySelector('.item.active') ) {
      this.fadeOut(event.currentTarget);
    }

    this.fadeIn(event.currentTarget);

  }

  fadeIn(currentElement) {
    const currentIndex = Array.prototype.indexOf.call(currentElement.parentNode.children, currentElement);
    const currentContent = this.contentWrapper.querySelector('.item.active');
    const selectedContent = this.contentWrapper.querySelector(`.content-services--list .item:nth-of-type(${currentIndex+1})`);

    currentElement.classList.add('active');

    this.fade({
      element: selectedContent,
      type: 'in',
      onAnimationBefore: () => {
        selectedContent.style.display = '';
        this.showContentWrapper();
      },
      onAnimationFinish: () => {
        selectedContent.classList.add('active');
        selectedContent.style.opacity = '';

        selectedContent.classList.remove('on-transition');
        if (currentContent) {
          currentContent.classList.remove('on-transition');
        }

        // Appareantly no longer needed
        // this.updateHeight(this.contentWrapper.querySelector('.item.active'));

        // Enable op-close event listener
        selectedContent.querySelector('.op-close').addEventListener('click', this.closeMobileDialog );
      }
    });
  }

  /**
   * currentTab DOM Element: the selected tab we will display
   * 
   **/
  fadeOut(currentTab) {
    const currentTabIndex = Array.prototype.indexOf.call(currentTab.parentNode.children, currentTab);
    const currentContent = document.querySelector('.content-services--list .item.active');
    const selectedContent = document.querySelector(`.content-services--list .item:nth-of-type(${currentTabIndex+1})`);

    this.tabsWrapper.querySelector('.item.active').classList.remove('active');

    this.fade({
      element: currentContent,
      type: 'out',
      onAnimationBefore: () => {
        
        if (this.isMobileLayout()) {
          this.contentWrapper.style.display = '';
          this.contentWrapper.style.opacity = '0';
        }

        selectedContent.classList.add('on-transition');
        selectedContent.style.display = '';
        selectedContent.style.opacity = '0';
        this.updateHeight(selectedContent);

        currentContent.classList.add('on-transition');        

      },
      onAnimationFinish: () => {
        currentContent.classList.remove('active');
        currentContent.style.display = 'none';
      }
    });
  }

  fade(options) {
    const type = options.hasOwnProperty('type') ? options.type : 'in';
    const thisElement = options.element;
    
    const animationOptions = {
      duration: 200,
      delay: 0,
      fill: 'forwards',
    };
    
    if (type === 'in') {
      animationOptions.direction = 'normal';
    } else if( type === 'out') {
      animationOptions.direction = 'reverse';
    }

    const fadeAnimation = thisElement.animate({
      opacity: [0, 1],
    }, animationOptions);

    typeof options.onAnimationBefore === 'function' && options.onAnimationBefore(fadeAnimation);

    fadeAnimation.onfinish = () => {
      fadeAnimation.cancel();
      typeof options.onAnimationFinish === 'function' && options.onAnimationFinish(fadeAnimation);
    }; 
  }

  updateHeight(newTab) {
    const currentListHeight = Misc.getHeight(document.querySelector('.content-services--list'));
    const newTabHeight = Misc.getHeight(newTab);
    
    const heightAnimation = this.contentWrapper.animate({
      height: [`${currentListHeight}px`, `${newTabHeight}px`],
    }, { duration: 200, delay: 0, fill: 'forwards' });

    heightAnimation.onfinish = () => {
      heightAnimation.cancel();
    }
  }

  closeMobileDialog(event) {
    event.preventDefault();
    this.hideContentWrapper();
  }

  hideContentWrapper() {
    this.fade({
      element: this.contentWrapper,
      type: 'out',
      onAnimationBefore: () => {
        if (this.isMobileLayout() && this.contentWrapper.querySelector('.item.active')) {
          this.contentWrapper.querySelector('.item.active').style.display = 'none';
          this.contentWrapper.querySelector('.item.active').classList.remove('active');
        }
      },
      onAnimationFinish: () => {
        this.contentWrapper.style.display = 'none';
        this.contentWrapper.classList.remove('active');
        this.contentWrapper.style.opacity = '';
        
        this.tabsInner.classList.remove('out');
        if (this.tabsWrapper.querySelector('.item.active')) {
          this.tabsWrapper.querySelector('.item.active').classList.remove('active');
        }
      }
    });
  }

  // Mobile
  showContentWrapper() {
    this.fade({
      element: this.contentWrapper,
      type: 'in',
      onAnimationBefore: () => {
        this.contentWrapper.style.display = '';
        this.contentWrapper.style.opacity = '';
        this.contentWrapper.classList.add('active');

        this.tabsInner.classList.add('out');
      }
    })
  }

  resetContentWrapper() {
    this.contentWrapper.style.display = '';
    this.contentWrapper.style.opacity = '';
    this.contentWrapper.classList.remove('active');
    this.tabsInner.classList.remove('out');
  }

}